<template>
  <b-container
    class="bedankt d-flex flex-column align-items-center justify-content-center"
  >
    <b-row>
      <b-col cols="12">
        <div class="question-card">
          <p class="m-0 bedankt__heading">
            Bedankt voor het versturen van uw gegevens.
          </p>
          <p class="m-0 bedankt__text">
            Er wordt zo spoedig mogelijk per email en/of <br />
            telefonisch contact met u opgenomen.
          </p>
        </div>
      </b-col>
      <b-col
        cols="12"
        md="5"
        class="d-flex justify-content-center pt-2 align-items-center"
      >
        <!-- <img
          src="../../assets/firstbannerGroup.png"
          class="ml-md-5 rightImage"
          alt=""
        /> -->
      </b-col>
    </b-row>

    <!-- <div class="d-flex justify-content-center my-2">
      <img
        class="img-fluid"
        src="../../assets/postcoode/bottoomStepsImg1.png"
      />
    </div> -->

    <!-- <span class="bedankt__text mt-2">
      Bedankt voor het versturen van uw gegevens.
      <br />
      Er wordt zo spoedig mogelijk per email en/of <br />
      telefonisch contact met u opgenomen.
    </span> -->

    <img
      v-if="profileId"
      referrerpolicy="no-referrer-when-downgrade"
      :src="`https://republish.verbeterthuis.nl/m/6312/abf8c433138a/?event=7386&unique_conversion_id=${profileId}`"
      style="width: 1px; height: 1px; border: 0px"
    />
  </b-container>
</template>

<script>
export default {
  name: "Bedankt",
  props: {
    profileId: {
      type: String,
      default: "",
    },
  },
  mounted() {
    let useScript = document.createElement("script");

    useScript.setAttribute(
      !(function (f, b, e, v, n, t, s) {
        if (f.fbq) return;
        n = f.fbq = function () {
          n.callMethod
            ? n.callMethod.apply(n, arguments)
            : n.queue.push(arguments);
        };

        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = "2.0";

        n.queue = [];
        t = b.createElement(e);
        t.async = !0;

        t.src = v;
        s = b.getElementsByTagName(e)[0];

        s.parentNode.insertBefore(t, s);
      })(
        window,
        document,
        "script",
        "https://connect.facebook.net/en_US/fbevents.js"
      ),

      fbq("init", "1719035818444105"),

      fbq("track", "PageView"),
      fbq("track", "Lead")
    ),
      document.head.appendChild(useScript);
  },
  created() {
    this.noscriptHtml =
      '<noscript><img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=1719035818444105&ev=PageView&noscript=1"/></noscript>';
  },
};
</script>

<style lang="scss" scoped>
.rightImage {
  width: 500px;
  height: 500px;
  @media (max-width: 1023.99px) {
    width: 100%;
    height: 100%;
  }
}
.bedankt {
  font-family: "Fira Sans", sans-serif;
  background: none !important;
  &__heading {
    font-size: 30px;
    line-height: 38px;
    color: #000000 !important;
    font-weight: 800 !important;
    text-align: start !important;
  }
  &__heading-red {
    color: #ff0033 !important;
  }
  &__text {
    font-size: 25px;
    color: #000000 !important;
    text-align: start;
  }

  @media (max-width: 1023.99px) {
    &__heading {
      font-size: 23px;
      line-height: 30px;
    }
  }
  @media (max-width: 767.99px) {
    &__heading {
      font-size: 20px;
      line-height: 25px;
    }
  }
  @media (max-width: 575.99px) {
    &__heading {
      font-size: 18px;
      line-height: 20px;
    }
  }
}
</style>
