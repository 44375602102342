<template>
  <div>
    <b-container class="py-md-3 py-2 navBar">
      <b-row class="justify-content-between px-2">
        <img :src="logo" width="100px" />
        <div>
          <div class="d-flex mt-2 align-items-center">
            <img
              :src="trust_img"
              width="80px"
              class="mr-2 small-screen-setting"
            />
            <img :src="stars" width="auto" class="small-screen-setting" />
          </div>
          <!-- <br /> -->
          <small class="pl-1 fs--14"
            >Trustscore <b>4.4</b> | <b>3058</b> reviews
          </small>
        </div>
      </b-row>
    </b-container>
    <div :class="page == `first` ? `headerSection` : `bannerSecond`">
      <FirstBanner @nextPage="next_page" v-if="page == `first`" />

      <div class="main-section" v-if="page == `second`">
        <FirstQuestion
          v-if="currentQuestion === 1 && page == `second`"
          @handleNextClick="handleNextClick"
        />
        <SwitchQuestion
          v-else-if="currentQuestion === 2"
          @handleNextClick="handleNextClick"
          :item="secondQueItems"
          :selected="isValuableVisible"
        />
        <SwitchQuestion
          v-else-if="currentQuestion === 3"
          @handleNextClick="handleNextClick"
          :item="thirdQueItems"
          :selected="entrance"
        />

        <Loader
          v-else-if="currentQuestion === 4"
          @handleNextClick="handleNextClick"
        />
        <Form
          v-else-if="currentQuestion === 5"
          @handleNextClick="handleNextClick"
        />

        <Bedankt :profileId="profileId" v-else-if="currentQuestion === 6" />
        <VerisureAlarm v-if="currentQuestion == 1 || currentQuestion == 5" />
        <FooterSection
          v-if="currentQuestion == 5 || currentQuestion == 1"
          :currentQuestion="currentQuestion"
        />
      </div>
      <VerisureAlarm
        :currentQuestion="currentQuestion"
        v-if="currentQuestion == 6"
      />
    </div>
    <FooterSection
      v-if="(currentQuestion != 1 && currentQuestion != 5) || page == 'first'"
      :currentQuestion="currentQuestion"
    />
  </div>
</template>

<script>
import logo from "@/assets/Postcodecheckerhh/woonveiligheid-logo.svg";
import stars from "@/assets/postcoode/stars.png";
import trust_img from "@/assets/postcoode/trust.png";
// components
import FirstBanner from "../components/Postcodecheckerhh/FirstBanner.vue";
import VerisureAlarm from "../components/Postcodecheckerhh/VerisureAlarm.vue";
import FirstQuestion from "../components/Postcodecheckerhh/FirstQuestion.vue";
import FooterSection from "../components/Postcodecheckerhh/Footer.vue";
import SwitchQuestion from "../components/Postcodecheckerhh/SwitchQuestion.vue";
import Loader from "../components/Postcodecheckerhh/Loader.vue";
import Bedankt from "../components/Postcodecheckerhh/Bedankt.vue";
import Form from "../components/Postcodecheckerhh/Form.vue";
export default {
  name: "Postcodecheckerhh",
  components: {
    FirstBanner,
    FooterSection,
    FirstQuestion,
    VerisureAlarm,
    Bedankt,
    Loader,
    SwitchQuestion,
    Form,
  },
  data() {
    return {
      page: `first`,
      logo,
      trust_img,
      stars,
      ///
      currentQuestion: 1,
      profileId: "",
      home: "",
      secondQueItems: {
        question: "Waar bevindt zich de hoofdingang van uw woning?",
        items: [
          { label: "Aan de straat" },
          { label: "Aan de tuin" },
          { label: "Gezamenlijke ingang" },
          { label: "Anders" },
        ],
      },
      isValuableVisible: "",
      thirdQueItems: {
        question:
          "Vindt u het interessant om het allernieuwste alarmsysteem uit te proberen?",
        items: [{ label: "Ja" }, { label: "Weet ik nog niet" }],
      },
      entrance: "",

      ///
    };
  },
  methods: {
    next_page() {
      this.page = "second";
    },
    handleNextClick(profile) {
      this.currentQuestion = this.currentQuestion + 1;
      window.scrollTo({ top: 0, behavior: "smooth" });
      if (profile) {
        this.profileId = profile;
      }
    },
  },
  mounted() {
    let useScript = document.createElement("script");

    useScript.setAttribute(
      !(function (f, b, e, v, n, t, s) {
        if (f.fbq) return;
        n = f.fbq = function () {
          n.callMethod
            ? n.callMethod.apply(n, arguments)
            : n.queue.push(arguments);
        };

        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = "2.0";

        n.queue = [];
        t = b.createElement(e);
        t.async = !0;

        t.src = v;
        s = b.getElementsByTagName(e)[0];

        s.parentNode.insertBefore(t, s);
      })(
        window,
        document,
        "script",

        "https://connect.facebook.net/en_US/fbevents.js"
      ),

      fbq("init", "1719035818444105"),

      fbq("track", "PageView")
    ),
      document.head.appendChild(useScript);
  },
  created() {
    document.title = "Woonveiligheid";

    this.noscriptHtml =
      '<noscript><img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=1719035818444105&ev=PageView&noscript=1"/></noscript>';
  },
};
</script>

<style lang="scss" scoped>
.main-section {
  background: #f2f2f2 !important;
  //   height: 95vh;
  padding-top: 40px;
  padding-bottom: 40px;
  height: 659px;

  @media (max-width: 1023.99px) {
    height: 619px;
  }
  @media (max-width: 767.99px) {
    height: 597px;
  }
  @media (max-width: 575.99px) {
    height: 537px;
  }
}
.navBar {
  font-family: "Fira Sans", sans-serif;
  max-width: 1040px !important;
}
.headerSection {
  font-family: "Fira Sans", sans-serif;
  background: url(../assets/postcoode/backgroundv4.png);
  background-size: cover;
  background-position-x: 70%;
  @media only screen and (max-width: 767.98px) {
    background-size: cover !important;
    background-position-x: left !important;
  }
}
.bannerSecond {
  font-family: "Fira Sans", sans-serif;
  background-color: #f2f2f2 !important;
}
@media (min-width: 1200px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    max-width: 1040px !important;
  }
}
.small-screen-setting {
  @media (max-width: 575.99px) {
    width: 70px !important;
  }
}
</style>
