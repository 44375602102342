<template>
  <b-container class="mb-5">
    <b-row class="justify-content-center">
      <b-col cols="12" md="10">
        <div
          class="question-card question-card__loader my-5 d-flex flex-column"
        >
          <p class="question-card__loader-heading text-center pb-3">
            Verisure analyseert nu je gegevens...
          </p>
          <b-progress :max="100" :value="value" class="mb-5">
            <b-progress-bar :value="value"></b-progress-bar>
          </b-progress>
          <div class="d-flex align-items-baseline" v-if="showCheckItem1">
            <svg
              class="mr-2"
              width="14"
              height="11"
              viewBox="0 0 14 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                id="Path"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M5 10.71L0 5.70999L1.41 4.29999L5 7.87999L12.59 0.289993L14 1.70999L5 10.71Z"
                fill="#00B67A"
              />
            </svg>
            <p class="font-weight-bold">
              Jij woont in een
              <span class="subtitle">{{ antwoordoptie1.toLowerCase() }}</span>
            </p>
          </div>
          <div class="d-flex align-items-baseline" v-if="showCheckItem2">
            <svg
              class="mr-2"
              width="14"
              height="11"
              viewBox="0 0 14 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                id="Path"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M5 10.71L0 5.70999L1.41 4.29999L5 7.87999L12.59 0.289993L14 1.70999L5 10.71Z"
                fill="#00B67A"
              />
            </svg>
            <p class="font-weight-bold">
              Jij vindt veiligheid van je woning
              <span class="subtitle">{{ antwoordoptie2.toLowerCase() }}</span>
            </p>
          </div>
          <div class="d-flex align-items-baseline" v-if="showCheckItem3">
            <svg
              class="mr-2"
              width="14"
              height="11"
              viewBox="0 0 14 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                id="Path"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M5 10.71L0 5.70999L1.41 4.29999L5 7.87999L12.59 0.289993L14 1.70999L5 10.71Z"
                fill="#00B67A"
              />
            </svg>
            <p class="font-weight-bold">
              Jij hebt
              <span
                v-if="
                  antwoordoptie3 &&
                  antwoordoptie3.trim().toLowerCase() ===
                    'nee, ik heb geen huisdieren'
                "
                class="subtitle"
              >
                geen huisdieren
              </span>
              <span
                v-else-if="
                  antwoordoptie3 &&
                  antwoordoptie3.trim().toLowerCase() ===
                    'ja, ik heb huisdieren'
                "
                class="subtitle"
              >
                huisdieren
              </span>
            </p>
          </div>
          <div class="d-flex align-items-baseline" v-if="showCheckItem4">
            <svg
              class="mr-2"
              width="14"
              height="11"
              viewBox="0 0 14 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                id="Path"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M5 10.71L0 5.70999L1.41 4.29999L5 7.87999L12.59 0.289993L14 1.70999L5 10.71Z"
                fill="#00B67A"
              />
            </svg>
            <p class="text-green font-weight-bold d-none d-md-none d-lg-flex">
              Jij komt in aanmerking om gratis het beveiligingssysteem van
              Verisure te testen!
            </p>
            <p class="text-green font-weight-bold d-none d-md-flex d-lg-none">
              Jij komt in aanmerking om gratis het <br />
              beveiligingssysteem van Verisure te testen!
            </p>
            <p class="text-green font-weight-bold d-flex d-md-none d-md-none">
              Jij komt in aanmerking om <br />
              gratis het beveiligingssysteem van <br />
              Verisure te testen!
            </p>
          </div>
          <div v-if="showBottomItem">
            <h5 class="font-weight-bold subtitle text-right pt-4">
              Wij sturen je nu door..
            </h5>
          </div>
        </div>
      </b-col>
      <!-- <b-col
        cols="12"
        md="5"
        class="d-flex justify-content-center pt-2 align-items-center"
      >
        <img src="../../assets/Group13.png" class="ml-md-5 rightImage" alt="" />
      </b-col> -->
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: "Loader",
  data() {
    return {
      value: 0,
      showCheckItem1: false,
      showCheckItem2: false,
      showCheckItem3: false,
      showCheckItem4: false,
      showBottomItem: false,
    };
  },
  props: {
    antwoordoptie1: String,
    antwoordoptie2: String,
    antwoordoptie3: String,
  },
  created() {
    // setTimeout(() => {
    // this.$emit("handleNextClick");
    // }, 3000);
  },
  mounted() {
    const interval = setInterval(() => {
      if (this.value < 20) {
        this.value += 1;
      } else if (!this.showCheckItem1) {
        this.showCheckItem1 = true;
        this.value = 20;
      } else if (this.value < 40) {
        this.value += 1;
      } else if (!this.showCheckItem2) {
        this.showCheckItem2 = true;
        this.value = 40;
      } else if (this.value < 60) {
        this.value += 1;
      } else if (!this.showCheckItem3) {
        this.showCheckItem3 = true;
        this.value = 60;
      } else if (this.value < 80) {
        this.value += 1;
      } else if (!this.showCheckItem4) {
        this.showCheckItem4 = true;
        this.value = 80;
      } else if (this.value < 100) {
        this.value += 1;
      } else if (!this.showBottomItem) {
        this.showBottomItem = true;
        this.value = 100;
        clearInterval(interval);
        setTimeout(() => {
          // perform your function here
          this.$emit("handleNextClick");
        }, 3000);
      }
    }, 60);
  },
};
</script>

<style lang="scss" scoped>
.progress-bar {
  background-color: #004380 !important;
  border-radius: 40px !important;
}
.progress {
  border-radius: 40px !important;
}
.text-green {
  color: #00b67a;
}
.subtitle {
  color: #004380;
}
.leftImage {
  width: 300px;
  height: 350px;
  @media (max-width: 1023.99px) {
    width: 100%;
    height: 50%;
  }
  @media (max-width: 766.99px) {
    width: 100%;
    height: 100%;
  }
}
.rightImage {
  width: 400px;
  height: 350px;
  @media (max-width: 1023.99px) {
    width: 100%;
    height: 50%;
  }
  @media (max-width: 766.99px) {
    width: 100%;
    height: 100%;
  }
}
.question-card {
  border-radius: 12px !important;
  padding: 80px 68px !important  ;
  filter: drop-shadow(0px 31px 81px 0px rgba(0, 17, 77, 0.2)) !important;
  @media (max-width: 575.99px) {
    padding: 40px 20px !important  ;
  }
  &__loader-heading {
    font-size: 40px;
    letter-spacing: 1px;
    color: #000000;
    font-weight: 800;
    text-align: center;
  }

  @media (max-width: 1023.99px) {
    &__loader-heading {
      font-size: 30px;
    }
  }
  @media (max-width: 767.99px) {
    &__loader-heading {
      font-size: 25px;
    }
  }
  @media (max-width: 575.99px) {
    &__loader-heading {
      font-size: 20px;
    }
  }
}
</style>
