<template>
  <b-container class="text-center my-5">
    <b-row>
      <b-col cols="12" xl="12">
        <b-progress :max="max" class="mb-3">
          <b-progress-bar
            :style="`width:${width}%`"
            :label="`Vraag ${question} van ${max}`"
          ></b-progress-bar>
        </b-progress>
      </b-col>
      <b-col cols="12" md="7">
        <div class="firstAlarm-question pb-5 pt-4 d-flex flex-column">
          <!-- <p class="text-justify question-card__text">
            Vraag {{ item?.question2 }} {{ item?.question3 }} van {{ max }}
          </p> -->
          <p class="question-card__subQuestion mb-0">
            {{ item?.subQuestion }}
          </p>
          <p class="question-card__question">
            {{ item?.question }}
          </p>

          <div class="">
            <div
              class="mt-3"
              v-for="(item, index) in item.items"
              :key="index"
              :disabled="loading"
              @click="selectItem(item.label)"
            >
              <b-form-radio
                :name="'radio-group-' + index"
                v-model="selected"
                switch
                size="lg"
                :value="item.label"
                class="radio-background d-flex"
              >
                <span
                  class="question-card__label"
                  :class="{ 'font-weight-bold': isSelected(item.label) }"
                  >{{ item.label }}</span
                >
              </b-form-radio>
            </div>

            <div v-if="currentQuestion === 2" class="d-flex mt-4">
              <b-col cols="12" lg="4">
                <div
                  @click="goToPreviousPage"
                  class="d-flex justify-content-start align-items-center back-arrow"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="12"
                    viewBox="0 0 22 12"
                    fill="none"
                  >
                    <path
                      d="M0.240943 6.65033C0.24119 6.6506 0.241396 6.65093 0.241684 6.6512L4.53456 11.4287C4.85617 11.7866 5.37635 11.7853 5.69647 11.4256C6.01656 11.0659 6.01532 10.4842 5.69372 10.1263L2.81148 6.91874L20.2107 6.91874C20.6644 6.91874 21.0322 6.50742 21.0322 5.99999C21.0322 5.49257 20.6644 5.08124 20.2107 5.08124L2.81152 5.08124L5.69368 1.87371C6.01528 1.51577 6.01651 0.93406 5.69643 0.574417C5.3763 0.21468 4.85608 0.21344 4.53452 0.571292L0.241641 5.34878C0.241395 5.34906 0.241189 5.34938 0.240901 5.34966C-0.0808664 5.7088 -0.0798383 6.29238 0.240943 6.65033Z"
                      fill="black"
                    />
                  </svg>
                  <span class="pl-4">Vorige</span>
                </div>
              </b-col>
              <!-- <span v-if="isError" class="mb-3 invalid-text"
                >Selecteer een optie</span
              > -->
              <!-- <b-button
                class="question-card__firstBtn px-md-4 px-3 py-3 d-flex align-items-center"
                size="md"
                @click="onNextClick"
              >
                Volgende vraag
                <img src="../../assets/rightArrowbtn.png" class="ml-3" />
              </b-button> -->
            </div>
            <div v-if="currentQuestion === 3" class="d-flex mt-4">
              <b-col cols="12" lg="4">
                <div
                  @click="goToPreviousPage"
                  class="d-flex justify-content-start align-items-center back-arrow"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="12"
                    viewBox="0 0 22 12"
                    fill="none"
                  >
                    <path
                      d="M0.240943 6.65033C0.24119 6.6506 0.241396 6.65093 0.241684 6.6512L4.53456 11.4287C4.85617 11.7866 5.37635 11.7853 5.69647 11.4256C6.01656 11.0659 6.01532 10.4842 5.69372 10.1263L2.81148 6.91874L20.2107 6.91874C20.6644 6.91874 21.0322 6.50742 21.0322 5.99999C21.0322 5.49257 20.6644 5.08124 20.2107 5.08124L2.81152 5.08124L5.69368 1.87371C6.01528 1.51577 6.01651 0.93406 5.69643 0.574417C5.3763 0.21468 4.85608 0.21344 4.53452 0.571292L0.241641 5.34878C0.241395 5.34906 0.241189 5.34938 0.240901 5.34966C-0.0808664 5.7088 -0.0798383 6.29238 0.240943 6.65033Z"
                      fill="black"
                    />
                  </svg>
                  <span class="pl-4">Vorige</span>
                </div>
              </b-col>
              <!-- <span v-if="isError" class="mb-3 invalid-text"
                >Selecteer een optie</span
              > -->
              <!-- <b-button
                class="question-card__firstBtn px-md-4 px-3 py-3 d-flex align-items-center"
                size="md"
                @click="onNextClick"
              >
                Ga verder
                <img src="../../assets/rightArrowbtn.png" class="ml-3" />
              </b-button> -->
            </div>
          </div>
          <div
            v-if="currentQuestion === 4"
            class="mt-3 d-flex flex-column align-items-start"
          >
            <b-form class="">
              <b-row>
                <svg
                  class="postcode-icon"
                  width="25"
                  height="26"
                  viewBox="0 0 25 26"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.5 23.1562C18.1091 23.1562 22.6562 18.6091 22.6562 13C22.6562 7.39086 18.1091 2.84375 12.5 2.84375C6.89086 2.84375 2.34375 7.39086 2.34375 13C2.34375 18.6091 6.89086 23.1562 12.5 23.1562Z"
                    stroke="#FF0033"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M8.2498 15.3437L8.85917 11.9297C8.97545 11.2949 9.28212 10.7106 9.73843 10.2543C10.1947 9.79794 10.7791 9.49127 11.4139 9.37499L14.8435 8.74999C15.1072 8.68122 15.3843 8.68261 15.6473 8.75402C15.9103 8.82542 16.15 8.96437 16.3427 9.15706C16.5354 9.34976 16.6744 9.58949 16.7458 9.85248C16.8172 10.1155 16.8186 10.3926 16.7498 10.6562L16.1404 14.0703C16.0241 14.7051 15.7175 15.2894 15.2612 15.7457C14.8048 16.202 14.2205 16.5087 13.5857 16.625L10.156 17.25C9.89236 17.3188 9.61527 17.3174 9.35228 17.246C9.0893 17.1746 8.84956 17.0356 8.65686 16.8429C8.46417 16.6502 8.32523 16.4105 8.25382 16.1475C8.18241 15.8845 8.18102 15.6074 8.2498 15.3437V15.3437Z"
                    stroke="#FF0033"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M12.5 13.7812C12.9315 13.7812 13.2812 13.4315 13.2812 13C13.2812 12.5685 12.9315 12.2188 12.5 12.2188C12.0685 12.2188 11.7188 12.5685 11.7188 13C11.7188 13.4315 12.0685 13.7812 12.5 13.7812Z"
                    stroke="#FF0033"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <b-col cols="12" lg="5" class="my-3 my-lg-0">
                  <b-input-group>
                    <b-form-input
                      class="py-4 radio-background-postcode d-flex"
                      v-model="zip"
                      :state="zipValidation"
                      placeholder="Postcode"
                      required
                    >
                    </b-form-input>
                  </b-input-group>
                  <small class="text-danger pr-5 pt-1" v-if="isInValid"
                    >Combinatie is onjuist</small
                  >
                </b-col>
                <b-col cols="12" lg="4" class="">
                  <b-input-group>
                    <b-form-input
                      class="py-4 radio-background-postcode d-flex"
                      v-model="house_number"
                      :state="houseNumberValidation"
                      placeholder="Huisnr."
                      required
                    >
                    </b-form-input>
                  </b-input-group>
                  <!-- <small class="text-danger" v-if="isInValid">
                    Huisnr is niet correct
                  </small> -->
                </b-col>
              </b-row>
            </b-form>
            <!-- <span v-if="isError" class="mb-3 invalid-text"
              >Selecteer een optie</span
            > -->
          </div>
          <div
            v-if="currentQuestion === 4"
            class="d-flex align-items-center mt-3 justify-content-start"
          >
            <b-col cols="6" xs="6" md="5" lg="4">
              <div
                @click="goToPreviousPage"
                class="d-flex justify-content-start align-items-center back-arrow"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="12"
                  viewBox="0 0 22 12"
                  fill="none"
                >
                  <path
                    d="M0.240943 6.65033C0.24119 6.6506 0.241396 6.65093 0.241684 6.6512L4.53456 11.4287C4.85617 11.7866 5.37635 11.7853 5.69647 11.4256C6.01656 11.0659 6.01532 10.4842 5.69372 10.1263L2.81148 6.91874L20.2107 6.91874C20.6644 6.91874 21.0322 6.50742 21.0322 5.99999C21.0322 5.49257 20.6644 5.08124 20.2107 5.08124L2.81152 5.08124L5.69368 1.87371C6.01528 1.51577 6.01651 0.93406 5.69643 0.574417C5.3763 0.21468 4.85608 0.21344 4.53452 0.571292L0.241641 5.34878C0.241395 5.34906 0.241189 5.34938 0.240901 5.34966C-0.0808664 5.7088 -0.0798383 6.29238 0.240943 6.65033Z"
                    fill="black"
                  />
                </svg>
                <span class="pl-4">Vorige</span>
              </div>
            </b-col>

            <b-col
              cols="6"
              xs="6"
              md="4"
              lg="5"
              class="d-flex justify-content-end justify-content-lg-end justify-content-md-start ml-lg-4 ml-xl-0"
            >
              <b-button
                class="question-card__firstBtn px-md-5 w-max px-3 py-3 d-flex align-items-center"
                size="lg"
                @click="onNextClick"
              >
                Volgende
                <img src="../../assets/rightArrowbtn.png" class="ml-3" />
              </b-button>
            </b-col>
          </div>
          <!-- <b-row class="py-2 justify-content-center">
        <b-col
          xs="12"
          md="12"
          class="mt-md-4 mt-3 d-flex justify-content-center"
        >
          <b-button
            class="question-card__btn px-md-4 px-3 py-2 d-flex align-items-center"
            size="md"
            @click="$emit('handleNextClick')"
          >
            Volgende vraag
            <img src="../../assets/verisurehouse/btn-arrow.png" class="ml-3" />
          </b-button>
        </b-col>
      </b-row> -->
        </div>
      </b-col>
      <b-col
        cols="12"
        md="5"
        class="d-flex justify-content-center pt-2 align-items-center"
      >
        <img
          v-if="currentQuestion === 2"
          src="../../assets/question-img2.png"
          class="ml-md-5 rightImage"
          alt=""
        />
        <img
          v-if="currentQuestion === 3"
          src="../../assets/question-img3.png"
          class="ml-md-5 rightImage"
          alt=""
        />
        <img
          v-if="currentQuestion === 4"
          src="../../assets/question-img4.png"
          class="ml-md-5 rightImage"
          alt=""
        />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "SwitchQuestion",

  props: {
    item: {
      type: Object,
      required: true,
    },
    selected: {
      type: String,
      default: "",
    },
    max: {
      type: Number,
      required: true,
    },
    currentQuestion: {
      type: Number,
      default: "",
    },
    question: {
      type: String,
      required: true,
    },
    profile: {
      type: String, // Or Object, depending on your requirements
      required: true, // Make sure this is passed down from the parent
    },
    width: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      zip: "",
      house_number: "",
      isError: false,
      isInValid: false,
      validationCheck: true,
      selected: "",
    };
  },
  methods: {
    ...mapActions(["setPostcode"]),

    goToPreviousPage() {
      this.$emit("previousQuestion");
    },

    onNextClick() {
      if (this.zipValidation && this.houseNumberValidation) {
        // If both fields are valid, proceed to the next step
        this.isInValid = false;
        this.$emit("handleNextClick");
        this.setPostcode({ zip: this.zip, house_number: this.house_number });
      } else {
        // If validation fails, show the error message
        this.isInValid = true;
      }
    },
    isSelected(label) {
      return this.selected === label;
    },

    selectItem(itemLabel) {
      this.selected = itemLabel;
      this.$emit("handleNextClick", this.profile, this.selected);

      // setTimeout(() => {
      //   if (this.currentQuestion >= 2 && this.currentQuestion <= 5) {
      //     this.currentQuestion++;
      //     this.$emit("handleNextClick");
      //   }
      // }, 1000);
    },
  },
  computed: {
    ...mapGetters(["activeUsers"]),
    zipValidation() {
      this.isInValid = false;
      if (this.zip == "") {
        this.validationCheck = false;
        return null;
      }
      let zipRegex = /^([0-9]{4}\s?[a-zA-Z]{2})$/;
      if (this.zip.match(zipRegex)) {
        this.validationCheck = true;

        return true;
      } else {
        this.validationCheck = false;
        return false;
      }
    },
    houseNumberValidation() {
      this.isInValid = false;
      if (this.house_number == "") {
        this.validationCheck = false;
        return null;
      }
      let zipRegex = /^\d+\s*([- ]?\s*[a-zA-Z]+)?$/;
      if (this.house_number.match(zipRegex)) {
        this.validationCheck = true;
        return true;
      } else {
        this.validationCheck = false;
        return false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.back-arrow {
  cursor: pointer;
}
.progress {
  border-radius: 40px;
}
.progress-bar {
  background-color: #004380;
  border-radius: 40px;
}
.postcode-icon {
  position: absolute;
  z-index: 999;
  left: 35px;
  bottom: 197px;
  @media (max-width: 1023.99px) {
    bottom: 217px;
    left: 40px;
  }
  @media (max-width: 767.99px) {
    bottom: 197px;
    left: 30px;
  }
}
.rightImage {
  width: 400px;
  height: 350px;
  @media (max-width: 1023.99px) {
    width: 100%;
    height: 60%;
  }
  @media (max-width: 766.99px) {
    width: 100%;
    height: 100%;
  }
}
.radio-background-postcode {
  cursor: pointer;
  background-color: #f1f1f1;
  border-radius: 50px;
  padding: 20px 0px 20px 60px;
}
.radio-background {
  cursor: pointer;
  background-color: #f1f1f1;
  border-radius: 50px;
  padding: 20px 0px 20px 60px !important;
}
.question-card {
  &__subQuestion {
    font-size: 20px;
    font-weight: 400;
    text-align: start;
  }
  &__firstBtn {
    background: #00b67a !important;
    border-color: #00b67a !important;
    // text-transform: uppercase;

    border-radius: 40px !important;
    font-size: 16px !important;
    border: none !important;
    // text-transform: uppercase !important;
    letter-spacing: 1px !important;
    color: #ffffff !important;
    font-weight: 600 !important;
    &:hover,
    &:focus,
    &:active {
      background-color: #00b67a !important;
      border: none !important;
      box-shadow: none !important;
    }
  }
  .check-items {
    min-width: fit-content;
    max-width: fit-content;
  }
  &__text {
    color: #000000;
    font-weight: 400;
  }
  &__question {
    // font-size: 30px;
    // border-top: 1px solid #f1f1f1;
    // padding-top: 20px;
    font-size: 25px;
    color: #000000;
    font-weight: 700;
    text-align: left;
  }
  &__label {
    font-size: 16px;
    color: #000000;
    font-weight: 400;
    padding-left: 15px;
  }

  @media (max-width: 1023.99px) {
    &__label {
      font-size: 16px;
    }
  }
  @media (max-width: 767.99px) {
    &__subQuestion {
      font-size: 27px;
    }
    &__question {
      font-size: 34px;
      line-height: 40px;
    }

    &__label {
      font-size: 15px;
    }
  }
  @media (max-width: 575.99px) {
    &__label {
      font-size: 14px;
    }
  }
}
</style>
