<template>
  <div class="trustpilot">
    <!-- <b-container class="alarm-section">
      <b-row class="py-2">
        <b-col xs="12" md="5" class="alarm-section__col">
          <p class="alarm-section__title">
            Het beveiligingssysteem van Verisure
          </p> -->
    <!-- <p class="alarm-section__desc">
            Het beveiligingssysteem van Verisure voor thuis biedt de beste
            bescherming voor jouw huis dankzij
            <span class="font-weight-bold"
              >de ultramoderne apparaten, een 24-uursservice en de professionele
              ondersteuning van onze beveiligingsexperts.</span
            >
            Vraag direct een gratis beveiligingsstudie voor jouw huis aan en
            ontdek de beste manier om jouw gezin te beschermen!
          </p> -->
    <!-- <ul class="alarm-section__desc">
            <li>Ultramoderne apparaten;</li>
            <li>24-uurservice;</li>
            <li>Professionele ondersteuning.</li>
          </ul>
        </b-col> -->
    <!-- <b-col md="2">
          <img
            src="../../assets/Frame 24.png"
            class="mt-5 d-md-flex d-none alarm-section__image"
            alt=""
          />
          <img
            src="../../assets/Framemobile.png"
            class="mt-5 d-md-none alarm-section__image"
            alt=""
          />
        </b-col> -->
    <!-- <b-col xs="12" md="5" class="mt-4"> <alarm-item /> </b-col>
      </b-row>
      <div class="d-flex justify-content-center mt-4">
        <img class="img-fluid d-none d-md-flex" :src="alarmImage" />
        <img class="img-fluid d-md-none d-flex" :src="alarmImages" />
      </div>
      <div class="d-flex justify-content-center mt-4">
        <b-button class="alarm-section__btn" @click="scrollToTop">
          Controleer uw postcode
        </b-button>
      </div>
    </b-container> -->
    <trustpilot />
  </div>
</template>

<script>
// import alarmImage from "../../assets/verisure_huisbeveiliging.png";
// import alarmImages from "../../assets/verisure_huisbeveiliging-hero_mobile.png";
// import watch from "../../assets/Group1.png";
// import user from "../../assets/Group2.png";
// import tool from "../../assets/Group3.png";
// import AlarmItem from "./AlarmItem.vue";
import Trustpilot from "./Trustpilot.vue";

export default {
  name: "VerisureAlarms",
  props: {
    currentQuestion: {
      type: Number,
      default: "",
    },
  },
  data() {
    return {
      // alarmImage,
      // alarmImages,
      // alarmItems: [
      //   { number: "4.500", text: "BEVEILIGING PROFESSIONALS", icon: watch },
      //   { number: "29", text: " SECONDES TOT REACTIE", icon: user },
      //   {
      //     number: "22.000",
      //     text: "VOORKOMEN POGINGEN TOT INBRAAK",
      //     icon: tool,
      //   },
      // ],
    };
  },
  components: {
    // AlarmItem,
    Trustpilot,
  },
  methods: {
    scrollToTop() {
      const formSection = document.getElementById("form-section");
      if (formSection) {
        formSection.scrollIntoView({ behavior: "smooth" });
      }
    },
    // next() {
    //   this.$emit("nextPage");
    // },
  },
  created() {
    document.title = "Verisure Beveiligingcheck";
  },
};
</script>

<style lang="scss" scoped>
.trustpilot {
  @media (max-width: 2765.99px) {
    margin-top: 100px;
  }
  margin-top: 100px;
}
.alarm-section {
  font-family: "Fira Sans", sans-serif;
  padding-top: 30px;
  //   padding-top: 300px;
  padding-bottom: 70px;
  // @media (max-width: 764.99px) {
  //   margin-top: 335px;
  // }
  // @media (max-width: 629.99px) {
  //   margin-top: 250px;
  // }
  // @media (max-width: 479.99px) {
  //   margin-top: 150px;
  // }
  &__image {
    width: 100%;
    @media (max-width: 765.99px) {
      margin: auto;
      display: flex;
    }
  }
  &__col {
    padding-left: 60px;
    // @media (min-width: 765.99px) (max-width: 1023.99px) {
    @media screen and (max-width: 1023.99px) and (min-width: 765.99px) {
      padding-left: 0px;
    }
  }
  &__title {
    font-family: "Fira Sans", sans-serif;
    font-size: 30px;
    font-weight: 700;
    line-height: 45px;
    color: #000000 !important;
    @media (max-width: 1023.99px) {
      font-size: 20px;
      line-height: 25px;
    }
  }
  &__desc {
    color: #000000 !important;
    font-size: 18px;
    font-weight: 400;
    @media (max-width: 1023.99px) {
      font-size: 13px;
    }
    @media (max-width: 766.99px) {
      font-size: 11px;
    }
  }
  &__btn {
    width: 35% !important;
    font-size: 20px;
    // letter-spacing: 1px;
    // text-transform: uppercase;
    color: #fff;
    font-weight: bold;
    background: #f29a41 !important;
    margin-top: 20px;
    border-radius: 30px;
    padding: 13px 20px;
    border: none !important;
  }
}
</style>
