<template>
  <div class="postcodeBanner" id="zipInput">
    <b-container
      fluid="lg"
      class="h-100 d-flex align-items-center overflow-hidden mb-0"
    >
      <b-row class="my-auto mx-md-0 mx-sm-4 mx-0 w-100 h-100">
        <b-col
          cols="12"
          md="7"
          class="d-flex flex-column justify-content-center pt-md-0 pt-4"
        >
          <p class="m-0 first-question__title">
            <span class="first-question__title-bold"
              >Test nu het allernieuwste alarmsysteem!</span
            >
          </p>
          <p class="first-question__description mb-0 mt-4">
            Check hier of uw woning in aanmerking komt om het allernieuwste
            alarmsysteem uit te proberen.
          </p>
          <p class="position-relative pt-md-5 pt-3">
            Vul hier uw postcode in:
            <img :src="arrow" class="arrowImg" />
            <img
              class="offerImage"
              src="../../assets/verisurehouse/fourtyoff.png"
            />
          </p>

          <div class="bg-white py-1 pl-2 mt-3 postcodeBanner__postcodeDiv">
            <b-form @submit.prevent="onSubmit" class="row">
              <b-form-input
                class="postcodeBanner__zipInput ml-3 px-0 my-auto text-center"
                v-model="zip"
                :state="zipValidation"
                placeholder="2000AB"
                maxLength="7"
                required
              ></b-form-input>
              <b-button
                pill
                block
                type="submit"
                variant="primary"
                class="py-3 position-relative primaryred-isolatie-btn d-flex align-items-center postcodeBanner__zipInput--btn"
              >
                Check mijn postcode
                <img :src="btn_arrow" width="25px"
              /></b-button>
            </b-form>
          </div>
         
          <small class="text-danger ml-4" v-if="isInValid" >Postcode niet correct</small>
        </b-col>
        <b-col class="d-md-none d-block">
          <div class="w-100 text-center h-100">
            <img :src="phone" width="200" />
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import arrow from "@/assets/postcoode/header_arrow.png";

import btn_arrow from "@/assets/postcoode/btn_rightArrow.png";
import arrowImg from "@/assets/postcoode/arrowB.png";
import phone from "@/assets/Postcodecheckerhh/phone.png";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "FirstBanner",

  data() {
    return {
      zip: "",
      arrow,
      btn_arrow,
      phone,
      arrowImg,
      isInValid:false,
      validationCheck: true,
    };
  },
  methods: {
    ...mapActions(["setPostcode", "setActiveUser", "getActiveUsers"]),

    onSubmit() {
      if (this.validationCheck) {
        this.$emit("nextPage");
        this.setPostcode(this.zip);
      }
      else{
          this.isInValid=true
      }
    },
  },
  mounted() {
    this.setActiveUser();
    this.getActiveUsers();
  },
  computed: {
    ...mapGetters(["activeUsers"]),
    zipValidation() {
    this.isInValid=false
      if (this.zip == "") {
        this.validationCheck = false;
        return null;
      }
      let zipRegex = /^([0-9]{4}\s?[a-zA-Z]{2})$/;
      if (this.zip.match(zipRegex)) {
        this.validationCheck = true;
        return true;
      } else {
        this.validationCheck = false;
        return false;
      }
  
    },
  },
};
</script>

<style lang="scss" scoped>
.arrowImg {
  position: absolute;
  right: 25%;
  width: 30px;
  @media (max-width: 991.98px) {
    display: none;
  }
}

@media (min-width: 1200px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    max-width: 1040px !important;
  }
}

.offerImage {
  position: absolute;
  right: -8%;
  height: 180%;
  @media (min-width: 768px) and (max-width: 849.98px) {
    right: -5%;
    top: 5px;
  }
  @media only screen and (max-width: 767.98px) {
    right: 0%;
    bottom: -70%;
  }
}
.postcodeBanner {
  font-family: "Fira Sans", sans-serif;
  height: 580px;

  &__smallHeader {
    color: #ff0032;
    // text-transform: uppercase;
    font-weight: bold;
  }
  &__arrowImg {
    position: absolute;
    width: 70px;
    top: -50px;
    @media (max-width: 991.98px) {
      right: 50px;
    }
    @media (max-width: 767.98px) {
      display: none;
    }
  }
  &__postcodeDiv {
    box-shadow: 2px 2px 10px 1px rgba(0, 0, 0, 0.1);
    border-radius: 50px;
    width: 65%;
    @media (min-width: 768px) and (max-width: 992.98px) {
      width: 75%;
    }
    .form-control:focus {
      color: #495057;
      background-color: #fff;
      border-color: rgba(128, 189, 255, 0) !important;
      outline: 0;
      box-shadow: none !important;
    }
    @media only screen and (max-width: 767.98px) {
      width: 100%;
    }
  }
  &__zipInput {
    border: none;
    width: 27%;
    font-size: 16px;
    @media (min-width: 640px) and (max-width: 767.98px) {
      width: 31.5%;
    }
    @media (min-width: 500px) and(max-width: 639.98px) {
      width: 29%;
    }
    // &::placeholder,
    // &::-webkit-input-placeholder,
    // &:-moz-placeholder,
    // &::-moz-placeholder,
    // &:-ms-input-placeholder {
    //   font-size: 14px;
    // }
    &--btn {
      width: 63%;
      font-size: 15px;
      justify-content: end;
      font-weight: bold;
      @media (max-width: 767.98px) {
        justify-content: center;
        font-size: 9px;
      }
      // @media (max-width: 449.98px) {
      //   justify-content: center;

      // }
      img {
        margin-left: 10px;

        @media (min-width: 450px) and(max-width: 767.98px) {
          position: absolute;
          right: 20px;
        }
        @media (max-width: 349.98px) {
          margin-left: 5px;
        }
      }
    }
  }
}
.bottomArrow {
  margin-bottom: -110px;
  width: 11px;
  @media only screen and (max-width: 767.98px) {
    margin-top: -40px;
  }
}
.w-65 {
  width: 65%;
  @media only screen and (max-width: 767.98px) {
    width: 100%;
  }
}
.primaryred-isolatie-btn {
  background: #ff6000 !important;
  border-color: #ff6000 !important;
  text-transform: uppercase;
  &:hover {
    background: #ff6000 !important;
  }
}
.first-question {
  max-width: 1040px;
  font-family: "Fira Sans", sans-serif;
  position: relative;
  &__arrow-down {
    width: 15px;
  }
  &__title {
    letter-spacing: 1px;
    color: #000000;
    // line-height: 45px;
    font-size: 30px;
    line-height: 35px;

    text-align: center;
    &-bold {
      font-weight: 800;
    }
  }
  &__description {
    // font-size: 30px;

    font-size: 26px;
    color: #000000;
    font-weight: 400;
    text-align: center;
    line-height: 35px;

    &-bold {
      font-weight: 800;
    }
  }

  &__gratis {
    border-radius: 10px;
    background-color: #ff0033;
    position: absolute;
    right: 0;
    // top: -9%;
    top: -10%;
    // padding: 13px 35px;
    padding: 13px 35px 13px 25px;
    &-text {
      // font-size: 20px;
      font-size: 18px;
      letter-spacing: 1px;
      color: #ffffff;
      font-weight: 600;
      text-align: center;
    }
    &-img {
      position: absolute;
      // right: -3%;
      // top: -14.5%;
      // width: 7%;
      right: -2.8%;
      top: -16%;
      width: 5.5%;
    }
  }
  &__sticker {
    position: absolute !important;
    width: 200px !important;
    height: 200px !important;
    left: -3.8%;
    top: -27%;
  }

  @media (max-width: 1023.99px) {
    &__title {
      font-size: 25px;
      line-height: 30px;
    }
    &__description {
      line-height: 30px;
      font-size: 20px;
    }
  }

  @media (max-width: 767.99px) {
    &__title {
      font-size: 26px;
      line-height: 25px;
    }
    &__description {
      line-height: 25px;
      font-size: 18px;
    }
  }

  @media (max-width: 380.99px) {
    &__title {
      font-size: 20px;
      line-height: 20px;
    }
    &__description {
      line-height: 20px;
      font-size: 16px;
    }
  }
}
</style>
