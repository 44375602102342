import { db, firebase } from "@/firebaseConfig";
import { RepositoryFactory } from "../../repository/RepositoryFactory";

const activeCountsCollection = firebase
  .firestore()
  .collection("postcodeCheckerActiveCounts");
const postCodeVerisureCheckerActiveUsers = firebase
  .firestore()
  .collection("postCodeVerisureCheckerActiveUsers");

let leads = RepositoryFactory.get("leads");
const state = {
  postcodeCheckerLoader: false,
  postcodeCheckerId: "",
  postcode: {},
  postcodeCheckerName: "",
  activeUsers: {},
  getErrorResponse: {},
};

const getters = {
  postcodeCheckerLoader: (state) => state.postcodeCheckerLoader,
  postcodeCheckerId: (state) => state.postcodeCheckerId,
  getPostcode: (state) => state.postcode,
  postcodeCheckerName: (state) => state.postcodeCheckerName,
  activeUsers: (state) => state.activeUsers,
  getErrorResponses: (state) => state.getErrorResponse,

};

const actions = {
  async getActiveUsers({ commit }) {
    try {
      await activeCountsCollection.onSnapshot((querySnapshot) => {
        let temp = [];
        querySnapshot.forEach((doc) => {
          temp.push({ ...doc.data(), id: doc.id });
        });
        commit("setActiveUsers", temp[0]);
      });
    } catch (error) {
      alert(error);
    }
  },
  async setActiveUser() {
    try {
      if (localStorage.getItem("uniqueId") == null) {
        let newDocRef = await postCodeVerisureCheckerActiveUsers.doc();
        const uniqueId = newDocRef.id;
        localStorage.setItem("uniqueId", uniqueId);
      }
      let uniqueUser = localStorage.getItem("uniqueId");
      postCodeVerisureCheckerActiveUsers.doc(uniqueUser).set({
        created: firebase.firestore.FieldValue.serverTimestamp(),
      });
    } catch (error) {
      console.log(error);
    }
  },
  async removeActiveUser() {
    try {
      await activeCountsCollection
        .doc("9plL2ddPlOvJF4K9GAcS")
        .get()
        .then((doc) => {
          console.log("remove");
          activeCountsCollection
            .doc("9plL2ddPlOvJF4K9GAcS")
            .update({ activeUsers: doc.data().activeUsers - 1 });
        });
    } catch (error) {
      alert(error);
    }
  },
  async setPostcode({ commit }, payload) {
    commit("setPostcode", payload);
  },

  async postcodeCheckerLead({ commit }, payload) {
    commit("setPostCodeLoader", true);
    commit(
      "setpostcodeCheckerName",
      payload.firstname + " " + payload.lastname
    );
    try {
      if (payload.bootCheck == true) {
        commit("setPostCodeLoader", false);

      } else {
        const response = await leads.postcodechecker_lead(payload);
        commit("setPostCodeLoader", false);
        const responseid = response.data.profile.id;
        commit("setId", responseid);
        return {
          success: true,
          profileid: responseid,
        };
      }

    } catch (error) {
      commit("setPostCodeLoader", false);
      // console.log(error.response.data.error);
      // return {
      //   success: true,
      //   profileid: null,
      // };
      if (error.response && error.response.data.error === "Conflict - duplicate") {
        console.log("error=>", error.response.data.error)
        return {
          success: true,
          profileid: null,
        };
      } else {
        const errorKey = error.response.data.error.match(/'([^']+)'/)[0].slice(1, -1);
        commit("setError", { [errorKey]: false });
        console.error("Error Detail:", errorKey);
      }
    }
  },
};

const mutations = {
  setActiveUsers(state, payload) {
    state.activeUsers = payload;
  },
  setPostCodeLoader(state, payload) {
    state.postcodeCheckerLoader = payload;
  },
  setId(state, payload) {
    state.postcodeCheckerId = payload;
  },
  setPostcode(state, payload) {
    state.postcode = payload;
  },
  setpostcodeCheckerName(state, payload) {
    state.postcodeCheckerName = payload;
  },
  setError(state, payload) {
    state.getErrorResponse = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
