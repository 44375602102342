<template>
  <b-container class="text-center">
    <b-row>
      <b-col cols="12" md="7">
        <div class="question-card my-5 d-flex flex-column">
          <p class="text-justify question-card__text">
            Vraag {{ item?.question2 }} {{ item?.question3 }} van {{ max }}
          </p>
          <p class="question-card__question">
            {{ item?.question }}
          </p>

          <div class="">
            <div
              class="mt-3"
              v-for="(item, index) in item.items"
              :key="index"
              @click="selectItem(item.label)"
            >
              <b-form-radio
                :name="'radio-group-' + index"
                v-model="selected"
                switch
                size="sm"
                :value="item.label"
                class="radio-background d-flex pl-5"
              >
                <span
                  class="question-card__label"
                  :class="{ 'font-weight-bold': isSelected(item.label) }"
                  >{{ item.label }}</span
                >
              </b-form-radio>
            </div>

            <div
              v-if="currentQuestion === 2"
              :class="[
                'd-flex',
                isError ? 'mt-3' : 'mt-md-5 mt-4',
                'flex-column',
                'align-items-end',
              ]"
            >
              <span v-if="isError" class="mb-3 invalid-text"
                >Selecteer een optie</span
              >
              <b-button
                class="question-card__firstBtn px-md-4 px-3 py-3 d-flex align-items-center"
                size="md"
                @click="onNextClick"
              >
                Volgende vraag
                <img src="../../assets/rightArrowbtn.png" class="ml-3" />
              </b-button>
            </div>
            <div
              v-if="currentQuestion === 3"
              :class="[
                'd-flex',
                isError ? 'mt-3' : 'mt-md-5 mt-4',
                'flex-column',
                'align-items-end',
              ]"
            >
              <span v-if="isError" class="mb-3 invalid-text"
                >Selecteer een optie</span
              >
              <b-button
                class="question-card__firstBtn px-md-4 px-3 py-3 d-flex align-items-center"
                size="md"
                @click="onNextClick"
              >
                Ga verder
                <img src="../../assets/rightArrowbtn.png" class="ml-3" />
              </b-button>
            </div>
          </div>
          <!-- <b-row class="py-2 justify-content-center">
        <b-col
          xs="12"
          md="12"
          class="mt-md-4 mt-3 d-flex justify-content-center"
        >
          <b-button
            class="question-card__btn px-md-4 px-3 py-2 d-flex align-items-center"
            size="md"
            @click="$emit('handleNextClick')"
          >
            Volgende vraag
            <img src="../../assets/verisurehouse/btn-arrow.png" class="ml-3" />
          </b-button>
        </b-col>
      </b-row> -->
        </div>
      </b-col>
      <b-col
        cols="12"
        md="5"
        class="d-flex justify-content-center pt-2 align-items-center"
      >
        <img
          src="../../assets/newheaderbeve.png"
          class="ml-md-5 rightImage"
          alt=""
        />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: "SwitchQuestion",

  props: {
    item: {
      type: Object,
      required: true,
    },
    selected: {
      type: String,
      default: "",
    },
    max: {
      type: Number,
      required: true,
    },
    currentQuestion: {
      type: Number,
      default: "",
    },
    // question: {
    //   type: String,
    //   required: true,
    // },
  },

  data() {
    return {
      isError: false,
    };
  },
  methods: {
    onNextClick() {
      if (this.selected == "") {
        this.isError = true;
        setTimeout(() => {
          this.isError = false;
        }, 1500);
      } else {
        this.$emit("handleNextClick");
      }
    },
    isSelected(label) {
      return this.selected === label;
    },
    selectItem(itemLabel) {
      this.selected = itemLabel;
    },
  },
};
</script>

<style lang="scss" scoped>
.rightImage {
  width: 400px;
  height: 350px;
  @media (max-width: 1023.99px) {
    width: 100%;
    height: 30%;
  }
  @media (max-width: 766.99px) {
    width: 100%;
    height: 100%;
  }
}
.radio-background {
  background-color: #f1f1f1;
  border-radius: 30px;
  padding: 10px 0px 10px 0px;
}
.question-card {
  &__firstBtn {
    background: #f29a41 !important;
    border-color: #f29a41 !important;
    // text-transform: uppercase;
    border-radius: 40px !important;
    font-size: 16px !important;
    border: none !important;
    // text-transform: uppercase !important;
    letter-spacing: 1px !important;
    color: #ffffff !important;
    font-weight: 600 !important;
    &:hover,
    &:focus,
    &:active {
      background-color: #f29a41 !important;
      border: none !important;
      box-shadow: none !important;
    }
  }
  .check-items {
    min-width: fit-content;
    max-width: fit-content;
  }
  &__text {
    color: #000000;
    font-weight: 400;
  }
  &__question {
    // font-size: 30px;
    border-top: 1px solid #f1f1f1;
    padding-top: 20px;
    font-size: 25px;
    color: #000000;
    font-weight: 700;
    text-align: left;
  }
  &__label {
    font-size: 16px;
    color: #000000;
    font-weight: 400;
    padding-left: 15px;
  }

  @media (max-width: 1023.99px) {
    &__label {
      font-size: 16px;
    }
  }
  @media (max-width: 767.99px) {
    &__question {
      font-size: 34px;
      line-height: 40px;
    }

    &__label {
      font-size: 15px;
    }
  }
  @media (max-width: 575.99px) {
    &__label {
      font-size: 14px;
    }
  }
}
</style>
