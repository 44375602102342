<template>
  <b-container class="text-center">
    <b-row>
      <b-col cols="12" md="7">
        <div
          class="question-card question-card__loader my-5 d-flex flex-column align-items-center justify-content-center"
        >
          <p class="question-card__loader-heading text-center pb-3">
            Wij controleren nu of<br />
            uw woning in aanmerking komt...
          </p>

          <b-spinner label="Loading..."></b-spinner>
        </div>
      </b-col>
      <b-col
        cols="12"
        md="5"
        class="d-flex justify-content-center pt-2 align-items-center"
      >
        <img
          src="../../assets/Group13.png"
          class="ml-md-5 rightImage"
          alt=""
        />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: "Loader",
  created() {
    setTimeout(() => {
      this.$emit("handleNextClick");
    }, 3000);
  },
};
</script>

<style lang="scss" scoped>
.rightImage {
  width: 400px;
  height: 350px;
  @media (max-width: 1023.99px) {
    width: 100%;
    height: 50%;
  }
  @media (max-width: 766.99px) {
    width: 100%;
    height: 100%;
  }
}
.question-card {
  &__loader-heading {
    font-size: 25px;
    letter-spacing: 1px;
    color: #000000;
    font-weight: 800;
    text-align: center;
  }

  @media (max-width: 1023.99px) {
    &__loader-heading {
      font-size: 30px;
    }
  }
  @media (max-width: 767.99px) {
    &__loader-heading {
      font-size: 25px;
    }
  }
  @media (max-width: 575.99px) {
    &__loader-heading {
      font-size: 20px;
    }
  }
}
</style>
