<template>
  <b-container class="first-question">
    <b-row>
      <b-col cols="12" md="7">
        <p class="first-question__title">
          <span class="first-question__title-bold"> Gefeliciteerd!</span>
        </p>
        <p class="first-question__description-text">
          Uw postcode is goedgekeurd voor deelname.
        </p>
        <p class="first-question__description">
          Check nu of uw woning in aanmerking komt om het meest innovatieve
          alarmsysteem te laten plaatsen,‌<span class="font-weight-bold">
            beantwoord 3 vragen.</span
          >
        </p>
        <b-row no-gutters class="my-md-3 my-2 justify-content-center">
          <b-col cols="4" xs="4" md="4">
            <!-- <img
          src="../../assets/verisurehouse/arrowdown.png"
          class="first-question__arrow-down"
        /> -->
          </b-col>
          <!-- <b-col cols="4" md="4" class="d-md-flex d-none justify-content-end">
        <div>
          <img src="../../assets/verisurehouse/arrow-right.png" class="mr-4" />
        </div>
      </b-col> -->
        </b-row>

        <div class="question-card my-4 d-flex flex-column">
          <p class="ml-3 mb-0 question-card__text">
            Vraag {{ question }} van {{ max }}
          </p>
          <p
            class="question-card__question text-md-left font-weight-bold mt-md-3 mt-2 mb-0"
          >
            {{ item?.question }}
          </p>

          <b-row class="py-2 justify-content-center">
            <b-col class="d-flex flex-column">
              <div
                class="mt-3"
                v-for="(item, index) in item.items"
                :key="index"
                @click="selectItem(item.label)"
              >
                <b-form-radio
                  :name="'radio-group-' + index"
                  v-model="selected"
                  switch
                  size="sm"
                  :value="item.label"
                  class="radio-background d-flex pl-5"
                >
                  <span
                    class="question-card__label"
                    :class="{ 'font-weight-bold': isSelected(item.label) }"
                    >{{ item.label }}</span
                  >
                </b-form-radio>
              </div>
              <span v-if="isError" class="mb-0 mb-3 invalid-text"
                >Selecteer een optie
              </span>
              <div class="d-flex justify-content-end mt-3">
                <b-button
                  class="question-card__firstBtn px-md-4 px-3 py-3 d-flex align-items-baseline"
                  size="md"
                  @click="onNextClick"
                >
                  Volgende vraag
                  <img src="../../assets/rightArrowbtn.png" class="ml-3" />
                </b-button>
              </div>
            </b-col>
          </b-row>
        </div>
      </b-col>
      <b-col
        cols="12"
        md="5"
        class="d-flex justify-content-center pt-2 align-items-center"
      >
        <img
          src="../../assets/newheaderbeve.png"
          class="ml-md-5 rightImage"
          alt=""
        />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: "FirstQuestion",

  props: {
    // home: {
    //   type: String,
    //   default: "",
    // },
    item: {
      type: Object,
      required: true,
    },
    selected: {
      type: String,
      default: "",
    },
  },

  components: {},

  data() {
    return {
      question: "1",
      max: 3,
      isError: false,
    };
  },
  methods: {
    onNextClick() {
      if (this.selected == "") {
        this.isError = true;
        setTimeout(() => {
          this.isError = false;
        }, 1500);
      } else {
        this.$emit("handleNextClick");
      }
    },
    isSelected(label) {
      return this.selected === label;
    },
    selectItem(itemLabel) {
      this.selected = itemLabel;
    },
  },
  computed: {
    shouldShowImage() {
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);
      const startDate = new Date("2023-10-09");
      startDate.setHours(0, 0, 0, 0);
      const endDate = new Date("2023-10-15");
      endDate.setHours(0, 0, 0, 0);

      return currentDate >= startDate && currentDate <= endDate;
    },
  },
};
</script>

<style lang="scss" scoped>
.radio-background {
  background-color: #f1f1f1;
  border-radius: 30px;
  padding: 10px 0px 10px 0px;
}
.rightImage {
  width: 400px;
  height: 350px;
  @media (max-width: 1023.99px) {
    width: 100%;
    height: 30%;
  }
  @media (max-width: 766.99px) {
    width: 100%;
    height: 100%;
  }
}
.first-question {
  max-width: 1040px;
  font-family: "Fira Sans", sans-serif;
  position: relative;
  &__arrow-down {
    width: 15px;
  }
  &__title {
    // font-size: 50px;
    font-size: 40px;
    letter-spacing: 1px;
    color: #000000;
    // line-height: 45px;
    // font-size: 28px !important;
    line-height: 35px;

    text-align: start;
    &-bold {
      font-weight: 800;
    }
  }
  &__description {
    // font-size: 30px;
    font-size: 19px;
    color: #000000;
    font-weight: 400;
    text-align: start;
    line-height: 35px;
    &-text {
      font-size: 26px;
      color: #000000;
      font-weight: 600;
      text-align: start;
      line-height: 35px;
    }
    &-bold {
      font-weight: 800;
    }
  }

  &__gratis {
    border-radius: 10px;
    background-color: #ff6000;
    position: absolute;
    right: 0;
    // top: -9%;
    top: -10%;
    // padding: 13px 35px;
    padding: 13px 35px 13px 25px;
    &-text {
      // font-size: 20px;
      font-size: 18px;
      letter-spacing: 1px;
      color: #ffffff;
      font-weight: 600;
      text-align: start;
    }
    &-img {
      position: absolute;
      // right: -3%;
      // top: -14.5%;
      // width: 7%;
      right: -2.8%;
      top: -16%;
      width: 5.5%;
    }
  }
  &__sticker {
    position: absolute !important;
    width: 200px !important;
    height: 200px !important;
    left: -3.8%;
    top: -27%;
  }

  @media (max-width: 1099.99px) {
    max-width: 95% !important;
    &__gratis {
      right: 0;
      top: -8%;
      // padding: 10px 30px;
      padding: 10px 30px 10px 20px;
      &-text {
        font-size: 16px;
      }
      &-img {
        right: -2.3%;
        top: -12.5%;
        width: 5.5%;
      }
    }
  }

  @media (max-width: 1023.99px) {
    &__title {
      // font-size: 35px;
      // line-height: 40px;
      font-size: 30px;
      line-height: 30px;
    }
    &__description {
      // font-size: 18px;
      // font-size: 16px;
      line-height: 30px;
      font-size: 20px;
    }

    &__gratis {
      // top: -7%;
      top: -8%;

      &-text {
        font-size: 16px;
      }
      &-img {
        // right: -2.3%;
        // top: -10.5%;
        // width: 52px;
        right: -2.3%;
        top: -13%;
        width: 48px;
      }
    }

    &__sticker {
      width: 150px !important;
      height: 150px !important;
      left: -3.8%;
      top: -20%;
    }
  }

  @media (max-width: 767.99px) {
    &__title {
      // font-size: 25px;
      // line-height: 35px;
      font-size: 20px;
      line-height: 30px !important;
      font-weight: 700;
    }
    &__description {
      // font-size: 13.5px;
      // font-size: 14px;
      line-height: 25px;
      font-size: 16px;
    }

    &__gratis {
      top: -7%;
      // padding: 8px 20px;
      padding: 8px 20px 8px 15px;

      &-text {
        font-size: 12px;
      }
      &-img {
        right: -2.3%;
        top: -10.5%;
        width: 35px;
      }
    }

    &__sticker {
      width: 100px !important;
      height: 100px !important;
      top: -16%;
    }
  }

  @media (max-width: 575.99px) {
    &__title {
      // font-size: 18px;
      // line-height: 25px;
      font-size: 40px;
      line-height: 20px;
    }
    &__description {
      // font-size: 11px;
      // font-size: 12px;
      line-height: 30px;

      font-size: 23px;
    }
    &__gratis {
      top: -7%;
      // padding: 6px 18px;
      padding: 6px 18px 6px 14px;

      &-text {
        font-size: 10px;
      }
      &-img {
        right: -3.3%;
        top: -10.5%;
        width: 30px;
      }
    }
    &__sticker {
      width: 85px !important;
      height: 85px !important;
      top: -13%;
      @media (max-width: 525px) {
        top: -15%;
      }
    }
  }
}

.question-card {
  padding: 30px 20px !important;
  &__question {
    // font-size: 30px;
    border-top: 1px solid #f1f1f1;
    padding-top: 20px;
    font-size: 27px;
    color: #000000;
    font-weight: 700;
  }
  &__text {
    color: #000000;
    font-weight: 400;
  }
  &__label {
    font-size: 16px;
    padding-left: 15px;
  }
  &__firstBtn {
    background: #f29a41 !important;
    border-color: #f29a41 !important;
    // text-transform: uppercase;
    border-radius: 40px !important;
    font-size: 16px !important;
    border: none !important;
    // text-transform: uppercase !important;
    letter-spacing: 1px !important;
    color: #ffffff !important;
    font-weight: 600 !important;
    &:hover,
    &:focus,
    &:active {
      background-color: #f29a41 !important;
      border: none !important;
      box-shadow: none !important;
    }
  }
  @media (max-width: 767.99px) {
    &__question {
      font-size: 28px;
    }
  }
}
</style>
